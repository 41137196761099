/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"

import SEO from "../components/seo"
import Aside from "../components/aside"

const NotFoundPage = () => (
  <div
    sx={{
      // height: "100%",
      display: "flex",
      flexDirection: ["column", "row"],
      flex: "1 1 0",
    }}
  >
    <SEO title="404: Not found" />
    <article>
      <h1>NOT FOUND</h1>
      <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
    </article>
    <Aside />
  </div>
)

export default NotFoundPage
